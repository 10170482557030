import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
export const VerticalBar = ({ title, hideConnect, positionFixed }) => {
  return (
    <>
      <div
        className="left-panel"
        style={{ position: positionFixed ? "fixed" : "absolute" }}
      >
        {title && (
          <div className="horizonral-subtitle">
            <span>
              <strong>{title}</strong>
            </span>
          </div>
        )}
        <div className="left-panel_social">
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/showcase/gravitatedxp/"
                target="_blank"
                rel='noreferrer'
              >
                <FontAwesomeIcon size="lg" icon={faLinkedin} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      {hideConnect ? (
        <div
          className="share-button showshare follow-share"
          style={{ position: positionFixed ? "fixed" : "absolute" }}
        >
          <span>FOLLOW US</span>
        </div>
      ) : (
        <div
          className="share-button showshare"
          style={{ position: positionFixed ? "fixed" : "absolute" }}
        >
          <span>CONNECT</span>
        </div>
      )}
    </>
  )
}
