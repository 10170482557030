import React from "react"
import { useState } from "react"
import { Button, Col, Form, Modal } from "react-bootstrap"
import { ContactSuccess } from "../animation/ContactSuccess"

export const SuccessModal = props => {
  const { onHide } = props

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header></Modal.Header>

      <Modal.Body>
        <>
          <ContactSuccess />
          <Button className="mt-5 close-modal-button" onClick={() => onHide()}>
            Back To Site
          </Button>
        </>
      </Modal.Body>
    </Modal>
  )
}
