import React, { useState } from "react"
import Layout from "../components/layout"
import "../assets/css/theme/contact.scss"
import mapImage from "../images/stock/map.png"
import gravTeam from "../images/stock/gravteam.png"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Footer } from "../components/landing/_Footer"
import { VerticalBar } from "../components/sitewide/VerticalBar"
import { SuccessModal } from "../components/sitewide/SuccessModal"
// import { Helmet } from "react-helmet"


const IndexPage = () => {
  const [modalShow, setModalShow] = React.useState(false)
  return (
    <Layout
      title="Gravitate - Contact Our Team."
      description="Reach out to see a demo or to chat with one of our industy experts"
      image={gravTeam}
    >
      <SuccessModal show={modalShow} onHide={() => setModalShow(false)} />
      <VerticalBar positionFixed hideConnect title="Contact Us" />
      <Container fluid className="p-0 outer-wrapper">
        <div className="accent-box"></div>
        <Row style={{ marginLeft: 0, marginRight: 0 }}>
          <Col lg={{ span: 6, offset: 1 }} xs={12} className="contact-main-col">
            <Container className="contact-container">
              <div className="position-relative">
                <h1 className="text-left pt-5">CONTACT US</h1>
                <p className="text-left contact-excerpt pt-3">
                  REACH OUT TO SEE A DEMO OR TO CHAT WITH <br /> ONE OF OUR
                  INDUSTRY EXPERTS
                </p>
                <ContactForm setModalShow={setModalShow} />
              </div>
              <div
                className="team-contact mt-5 pr-4"
                style={{
                  backgroundImage: `url(${gravTeam})`,
                  backgroundPosition: "center",
                }}
              >
                <div className="contact-box">
                  <Row className="contact-row">
                    <Col lg={4}>
                      <p className="contact-detail-header">01.Write :</p>
                    </Col>
                    <Col lg={8}>
                      <a
                        className="text-left"
                        href="mailto:info@gravitate.energy"
                      >
                        info@gravitate.energy
                      </a>
                    </Col>
                  </Row>
                  <Row className="contact-row">
                    <Col lg={4}>
                      <p className="contact-detail-header">02.Call :</p>
                    </Col>
                    <Col lg={8}>
                      <a className="text-left" href="tel:713-360-9941‬">
                        (713) 360-9941‬
                      </a>
                    </Col>
                  </Row>
                  <Row className="contact-row">
                    <Col lg={4}>
                      <p className="contact-detail-header">03.Visit :</p>
                    </Col>
                    <Col lg={8}>
                      <a className="text-left" style={{ lineHeight: "20px" }}>
                        620 N COLLEGE AVE,
                        <br /> FAYETTEVILLE, AR
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </Col>
          <Col className="mobile-hide" lg={{ span: 4, offset: 1 }} xs={0}>
            <div
              className="map-image"
              style={{ backgroundImage: `url(${mapImage})` }}
            ></div>
          </Col>
        </Row>
        <Footer />
      </Container>
      <div className="accent-box-bottom"></div>
    </Layout>
  )
}

const ContactForm = ({ setModalShow }) => {
  const formSubmitter = ev => {
    ev.preventDefault()
    const form = ev.target

    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setModalShow(true)
      } else {
        console.log("uh oh form broke")
      }
    }
    xhr.send(data)
  }
  return (
    <>
      <Form
        className="pt-4"
        onSubmit={formSubmitter}
        action="https://formspree.io/f/mbjpgwbw"
        method="POST"
      >
        {/* greenhouse embed */}
        {/* <div id="grnhse_app">
        <Helmet>
          <script src="https://boards.greenhouse.io/embed/job_board/js?for=gravitate"></script>
        </Helmet>
      </div> */}

        <Form.Row className="pb-2">
          <Form.Group as={Col} controlId="formGridName">
            <Form.Control name="Name" placeholder="Name" />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Control name="Email" type="email" placeholder="Email" />
          </Form.Group>
        </Form.Row>

        <Form.Row className="pb-2">
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Control
              name="Telephone"
              type="telephone"
              placeholder="Phone"
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridSubject">
            <Form.Control name="Subject" placeholder="Subject" />
          </Form.Group>
        </Form.Row>

        <Form.Group className="pb-2" controlId="formGridMessage">
          <Form.Control name="Message" as="textarea" placeholder="Message" />
        </Form.Group>

        <div className="button-container">
          <Button variant="primary" type="submit">
            Send Message
          </Button>
        </div>
      </Form>
      <div>

      </div>
    </>
  )
}
export default IndexPage
